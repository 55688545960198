<template>
  <li
    v-if="$can('index', 'administration-insurance')"
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon-cogwheel-1"></i>
      <span class="menu-text"> Administration Insurance </span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
      <ul class="menu-subnav">
        <router-link
          to="/admin-insurance/admin-data-insurance"
          v-if="$can('index', 'admin-data-insurance')"
          v-slot="{ href, navigate, isActive, isExactActive }">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-file-2"></i>
              <span class="menu-text"> Admin Data insurance </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/admin-insurance/menberships-dashboard-insurance"
          v-if="$can('admindata', 'administration')"
          v-slot="{ href, navigate, isActive, isExactActive }">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-file-2"></i>
              <span class="menu-text"> Admin Menbership insurance </span>
            </a>
          </li>
        </router-link>
        <!-- <router-link
          to="/admin-insurance/users-insurance"
          v-if="$can('index', 'users')"
          v-slot="{ href, navigate, isActive, isExactActive }">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-users"></i>
              <span class="menu-text"> Users insurance </span>
            </a>
          </li>
        </router-link> -->
        <!-- <router-link
          to="/admin-insurance/facilities"
          v-if="$can('index', 'facilities')"
          v-slot="{ href, navigate, isActive, isExactActive }">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon far fa-building"></i>

              <span class="menu-text"> Facilities </span>
            </a>
          </li>
        </router-link> -->
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/admin-insurance/appointment'),
          }">
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon far fa-map"></i>
            <span class="menu-text">Settings Appointment insurance</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text"></span>
                </span>
              </li>

              <router-link
                to="/admin-insurance/appointment/packages"
                v-if="$can('index', 'medication')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Packages</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/medication"
                v-if="$can('index', 'medication')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Medications</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/campaign"
                v-if="$can('index', 'campaign')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">campaign</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/membership-parameters"
                v-if="$can('index', 'membership-parameters')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Memberships Parameters</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/appointment-messages"
                v-if="$can('index', 'appointment-messages')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Appointment messages</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/allergies"
                v-if="$can('index', 'allergies')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Allergies</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/ailment"
                v-if="$can('index', 'ailments')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Personal pathological history</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/test"
                v-if="$can('index', 'tests')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Tests</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/diagnostic-image"
                v-if="$can('index', 'diagnostic_imagings')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Diagnostic imaging</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/diagnostic-imaging-category"
                v-if="$can('index', 'category_diagnostic_imagings')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Diagnostic Imaging Category</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/procedures"
                v-if="$can('index', 'procedures')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Procedures</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/category_lab"
                v-if="$can('index', 'category_lab')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Laboratories category</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/laboratory"
                v-if="$can('index', 'laboratory')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Laboratories</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/review-system"
                v-if="$can('index', 'review_of_system')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Review System</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/review-system-category"
                v-if="$can('index', 'cat_review_of_system')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Review System Category</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/treatment"
                v-if="$can('index', 'treatments')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Treatments</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/diagnoses"
                v-if="$can('index', 'diagnoses')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Diagnoses</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/consult-price"
                v-if="$can('index', 'consult_prices')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Consult Price</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/admin-insurance/appointment/visit"
                v-if="$can('index', 'type_visits')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Type visits</span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/admin-insurance/appointment/add-name-insurance"
                v-if="$can('index', 'add-name-insurance')"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Add name insurace</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
  },
}
</script>

<style></style>
