<template>
  <li
    v-if="$can('index', 'cash_accounts')"
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{
      'menu-item-open': hasActiveChildren('/insurance-account'),
    }"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon far fa-money-bill-alt"></i>
      <span class="menu-text"> Cash Account </span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
      <ul class="menu-subnav">
        <router-link
          to="/insurance-cash-account"
          v-if="$can('index', 'insurance-cash_accounts')"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-cogwheel-1"></i>
              <span class="menu-text"> Cash Account </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/insurance-not-closed-cash"
          v-if="$can('index', 'insurance-not_closed_cash')"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-open-box"></i>
              <span class="menu-text">Open Box</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/insurance-cash-daily-boxes"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-users"></i>
              <span class="menu-text"> Daily Boxes </span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style></style>
